import { preloadImages, getInitialPosition, getDistance, map } from './utils';
import { TextAnimator } from './text-animator.js';
import { gsap } from 'gsap';

// body
const body = document.body;

// .grid__item
const gridItems = [...document.querySelectorAll('.grid__item')];

// .prime (clip path animation will be on the element)
const prime = document.querySelector('.prime');

// image .image--bg
const imageBg = prime.querySelector('.image--bg');

// View all projects link
const viewAllProjects = prime.querySelector('.nav__item--active');

// back button
const back = document.querySelector('.grid__item > .back')

let isAnimating;

const init = () => {
    document.querySelectorAll('.list__item').forEach(item => {
      const cols = Array.from(item.querySelectorAll('.hover-effect'));
      const animators = cols.map(col => new TextAnimator(col));
  
      item.addEventListener('mouseenter', () => {
        animators.forEach(animator => animator.animate());
      });
    });
  
    // Same for all links
    document.querySelectorAll('a.hover-effect').forEach(item => {
      const animator = new TextAnimator(item);
      item.addEventListener('mouseenter', () => {
        animator.animate();
      });
    });
  };
  
  init();

// Animation / Show images carousel
const showImages = () => {
    
    if ( isAnimating ) return;
    isAnimating = true;

    const tl = gsap.timeline({
        onComplete: () => {
            // pointer events
            body.classList.add('content-open');
            isAnimating = false;
        },
        defaults: {
            duration: 1,
            ease: 'expo.inOut',
        }
    })
    .addLabel('start', 0)

    for (const gridItem of gridItems) {
        const {x,y} = getInitialPosition(gridItem);
        const delay = map(getDistance(gridItem), 0, 2000, 0, 3.5);

        tl.set(gridItem, {
            x: x,
            y: y,
            opacity: 0
        }, 'start')
        .to(gridItem, {
            x: 0,
            y: 0,
            delay: delay,
            opacity: 1
        }, 'start+=0')
    }
    
    tl.to(imageBg, {
        scale: 1.1
    }, 'start')
    .to(prime, {
        startAt: {clipPath : 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)'},
        clipPath: 'polygon(50% 50%, 50% 50%, 50% 50%, 50% 50%)'
    }, 'start')
    
}

// Back animation
back.addEventListener('click', () => {
    
    if ( isAnimating ) return;
    isAnimating = true;

    const tl = gsap.timeline({
        onComplete: () => {
            body.classList.remove('content-open');
            isAnimating = false;
        },
        defaults: {
            duration: 1,
            ease: 'expo.inOut',
        }
    })
    .addLabel('start', 0)
    .to(prime, {
        startAt: {clipPath : 'polygon(50% 50%, 50% 50%, 50% 50%, 50% 50%)'},
        clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)'
    }, 'start+=0.4')
    .to(imageBg, {
        startAt: {scale: 1.4},
        scale: 1
    }, 'start+=0.4');

    for (const gridItem of gridItems) {
        const {x,y} = getInitialPosition(gridItem);
        const delay = map(getDistance(gridItem), 0, 5000, 0.9, 0);

        tl.to(gridItem, {
            x: x,
            y: y,
            delay: delay,
            opacity: 0
        }, 'start')
    }

});

window.addEventListener('load', () => showImages());

        const videoId = 'AZwK6tAxjGU';
        const videoId2 = '1WSkOih3cAo';
        // Function to load the YouTube iframe
        function loadYouTubePlayer() {
            const playerDiv = document.getElementById('player');
            playerDiv.innerHTML = `
                <iframe src="https://www.youtube.com/embed/${videoId}" 
                        frameborder="0" 
                        allow="autoplay; encrypted-media" 
                        allowfullscreen>
                </iframe>
            `;
            setTimeout(() => {
                playerDiv.classList.add('loaded');
            }, 100); // Small delay to ensure the transition is visible
        }

                function loadYouTubePlayer2() {
            const playerDiv = document.getElementById('player2');
            playerDiv.innerHTML = `
                <iframe src="https://www.youtube.com/embed/${videoId2}" 
                        frameborder="0" 
                        allow="autoplay; encrypted-media" 
                        allowfullscreen>
                </iframe>
            `;
            setTimeout(() => {
                playerDiv.classList.add('loaded');
            }, 100); // Small delay to ensure the transition is visible
        }

        // Load the player after 3 seconds
         setTimeout(loadYouTubePlayer, 4500);
         setTimeout(loadYouTubePlayer2, 5000);
function loadSongLinks() {
    const linksDiv = document.getElementById('linksContainer');
    const linksDiv2 = document.getElementById('linksContainer2');

    setTimeout(() => {
        linksDiv.classList.add('showLinks')
        linksDiv2.classList.add('showLinks')
    }, 5000)
}
// Preload images
preloadImages('.image__inner').then(() => document.body.classList.remove('loading')).then(() => loadSongLinks());